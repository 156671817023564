import { useCallback, useContext, useEffect } from 'react';
import { useExchangeRates } from '@Global/apollo/query/getExchangeRates/useExchangeRates';
import { Currency, Money, StakeValueClass, WalletToken } from '@Global/apollo/types';
import { IS_LOCAL } from '@Global/consts';
import { AppContext, ECurrenciesPair, TAppContext } from '@Global/store/appContext';
import { EIconType } from '@Global/ui/icon/iconsMap';

export type TTotalBalanceUSD = {
  includes: Currency[];
  excludes: Currency[];
  total: number;
};

const EXCHANGE_RATES_REFETCH_TIMEOUT = 60000;

export const useUpdateLocalTONToCurrenciesRate = () => {
  const { setCurrencyRate } = useContext(AppContext);
  const { fetchExchangeRates } = useExchangeRates();

  const updateRates = useCallback(
    () =>
      fetchExchangeRates().then((res) => {
        const rates = res?.data?.exchangeRates || [];
        for (const r of rates) {
          setCurrencyRate((r.source + r.target) as ECurrenciesPair, parseFloat(r.amount));
        }
      }),
    [setCurrencyRate],
  );

  useEffect(() => {
    updateRates();
    const interval = setInterval(() => {
      try {
        updateRates();
      } catch (e) {
        console.log(e);
      }
    }, EXCHANGE_RATES_REFETCH_TIMEOUT);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export const usdToTon = (tonValue: number, rate: number): number => {
  return parseFloat((tonValue * rate).toFixed(1));
};

export const moneyToAmountInCurrency = (
  money: Money,
  currency: Currency,
  currencyRates: TAppContext['currencyRates'],
): number | undefined => {
  if (money.currency === currency) {
    return money.amount;
  }

  const pairLabel = money.currency + currency;

  // console.log(currencyRates, pairLabel);
  // @ts-ignore
  if (typeof currencyRates[pairLabel] !== 'undefined') {
    //@ts-ignore
    return Math.floor(money.amount * currencyRates[pairLabel] * 100) / 100;
  }

  return undefined;
};

export const getTotalBalanceUSD = (
  tokens: WalletToken[],
  currencyRates: TAppContext['currencyRates'],
): TTotalBalanceUSD => {
  const includes: Currency[] = [];
  const excludes: Currency[] = [];
  let total = 0;

  for (const { balance } of tokens) {
    const amount = moneyToAmountInCurrency(balance, Currency.Usd, currencyRates);
    if (amount) {
      includes.push(balance.currency);
      total += amount;
    } else {
      excludes.push(balance.currency);
    }
  }

  return { total, includes, excludes };
};

/**
 * Formats total balance amount with spaces for thousands and comma for decimals.
 * Rounds down at most to 2 decimal places. Adds a '$' sign in front.
 *
 * Examples:
 * 0          -> $0
 * 1          -> $1
 * 1.24       -> $1,24
 * 1.359      -> $1,35
 * 1000       -> $1 000
 * 2322000.23 -> $2 322 000,23
 *
 * @param amount number
 * @returns string
 */
export const formatTotalBalanceUSD = (amount: number) => {
  return '$' + formatNumberWithSpaces(Math.floor(amount * 100) / 100);
};

export const formatNumberWithSpaces = (amount: number) => {
  let [int, decimal] = amount.toString().split('.');
  int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return decimal ? int + ',' + decimal : int;
};

export const round = (amount: number, placesMultiplier = 1e9) => {
  return Math.floor(amount * placesMultiplier) / placesMultiplier;
};

export const getTokenIconType = (currency: Currency): EIconType => {
  switch (currency) {
    case Currency.Ton:
      return EIconType.TON_TOKEN;
    case Currency.Fntf:
      return EIconType.FT_TOKEN;
    case Currency.Fton:
      return EIconType.FTON;
    case Currency.Xtr:
      return EIconType.XTR;
  }

  return EIconType.USDT;
};

// TODO: Merge min and currency into Money object
export type TStakeClass = {
  min: number;
  currency: Currency;
  name: StakeValueClass;
  options: TStakeClassOption[];
};

export type TStakeClassOption = {
  days: number;
  apy: number;
};

// Should be sorted by `min` in decreasing order for helper functions to work properly
export const stakeClasses: TStakeClass[] = [
  {
    min: 450_000,
    currency: Currency.Fton,
    name: StakeValueClass.Platinum,
    options: [
      { days: 90, apy: 150 },
      { days: 180, apy: 160 },
    ],
  },
  {
    min: 150_000,
    currency: Currency.Fton,
    name: StakeValueClass.Gold,
    options: [
      { days: 90, apy: 120 },
      { days: 180, apy: 130 },
    ],
  },
  {
    min: 30_000,
    currency: Currency.Fton,
    name: StakeValueClass.Silver,
    options: [
      { days: 90, apy: 110 },
      { days: 180, apy: 120 },
    ],
  },
  {
    min: 3_000,
    currency: Currency.Fton,
    name: StakeValueClass.Bronze,
    options: [
      { days: 90, apy: 100 },
      { days: 180, apy: 110 },
    ],
  },
];

export const minAvailableStakeDeposit = stakeClasses[stakeClasses.length - 1];

export const getStakeClass = (amount: number) => {
  for (const stake of stakeClasses) {
    if (amount >= stake.min) {
      return stake;
    }
  }
  return;
};

/**
 * Ежедневный_доход = (Сумма_стейкинга * (1+APY)^(Длительность_стейкинга / 365) - Сумма_стейкинга) / Длительность_стейкинга
 * @param deposit Сумма_стейкинга
 * @param apy APY 110% -> 1.1, 150% -> 1.5
 * @param duration Длительность_стейкинга
 * @returns Ежедневный_доход
 */
export const getStakeDailyAccruals = (deposit: number, apy: number, duration: number) => {
  return getStakeTotalIncome(deposit, apy, duration) / duration;
};

/**
 * Итоговый доход от введенной суммы = Сумма_стейкинга * (1+APY)^(Длительность_стейкинга / 365) - Сумма_стейкинга
 * @param deposit Сумма_стейкинга
 * @param apy APY 110% -> 1.1, 150% -> 1.5
 * @param duration Длительность_стейкинга
 * @returns Итоговый доход от введенной суммы
 */
export const getStakeTotalIncome = (deposit: number, apy: number, duration: number) => {
  return deposit * (1 + apy) ** (duration / 365) - deposit;
};

export const getStakeBackground = (stakeClass: StakeValueClass) => {
  switch (stakeClass) {
    case StakeValueClass.Platinum:
      return 'tw-bg-stake-platinum';
    case StakeValueClass.Gold:
      return 'tw-bg-stake-gold';
    case StakeValueClass.Silver:
      return 'tw-bg-stake-silver';
    case StakeValueClass.Bronze:
      return 'tw-bg-stake-bronze';
    default:
      return '';
  }
};

export const formatThousandsWithK = (amount: number): string => {
  if (amount < 1000) {
    return amount.toString();
  }
  return (amount / 1000).toString() + 'K';
};

export const getExchangeRate = (from: Currency, to: Currency, currencyRates: TAppContext['currencyRates']): number => {
  const pair = (from + to) as ECurrenciesPair;
  if (currencyRates[pair]) {
    return currencyRates[pair];
  }
  const fromPair = (from + Currency.Usd) as ECurrenciesPair;
  const toPair = (to + Currency.Usd) as ECurrenciesPair;
  if (currencyRates[fromPair] && currencyRates[toPair]) {
    const fromUSD = currencyRates[fromPair];
    const toUSD = currencyRates[toPair];
    return fromUSD / toUSD;
  }
  return 0;
};

/**
 * Collection of percent fee values for each currency pair.
 * Key = Currency from + Currency to
 *
 * For example:
 * If fee for swapping TON to FTON is 3% then { TONFTON: 3 }
 */
export const swapFeeRates: { [key in ECurrenciesPair]?: number } = {
  TONFTON: 3,
};

export const getFeeRate = (fromCurrency: Currency, toCurrency: Currency) => {
  const pair = (fromCurrency + toCurrency) as ECurrenciesPair;
  const rate = swapFeeRates[pair] || 0;
  return 1 - rate / 100;
};

export const getExchangeFee = (amount: number, from: Currency, to: Currency): Money | undefined => {
  const pair = (from + to) as ECurrenciesPair;
  if (!swapFeeRates[pair]) {
    return;
  }
  return { amount: round(amount * (1 - getFeeRate(from, to))), currency: from };
};

export const dailyLimits: { [key in Currency]?: number } = {
  TON: 50,
};

export const getDailyLimit = () => {};
