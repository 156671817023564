import { EGameLabel } from '@Global/consts';
import { TFantonAppTranslation } from '@Global/i18n';

const PTTranslation: TFantonAppTranslation = {
  'any-player-onboarding': {
    label: 'Novas partidas individuais',
    description: 'Agora, você tem a opção de selecionar qualquer posição de jogador em partidas individuais',
  },
  'community-onboarding': {
    label: `Junte-se à nossa comunidade`,
    description: `Não perca as últimas atualizações, torneios e promoções do Fanton!`,
    actions: {
      skip: 'Skip',
      join: 'Join',
    },
  },
  'filters.advanced-settings.label': 'Configurações avançadas',
  'filters.advanced-settings.reset-btn.label': 'Reset settings',
  'common.statistics-for-the-last-n-matches': 'Estatísticas das últimas {{n}} partidas',
  'common.average-for-the-match': 'Média por partida',
  'common.min': 'min',
  'common.points': 'Pontos',
  'common.tournaments.labels': {
    [EGameLabel.EPL]: 'Inglaterra',
    [EGameLabel.LALIGA]: 'Espanha',
    [EGameLabel.LIGA1]: 'França',
    [EGameLabel.BUNDESLIGA]: 'Alemanha',
    [EGameLabel.SERIE_A]: 'Itália',
    [EGameLabel.BRA1]: 'Brasil',
    [EGameLabel.TOP5]: 'TOP-5 Unidos',
    [EGameLabel.WC2022]: 'Qatar 2022',
    [EGameLabel.EURO2024]: 'Euro 2024',
    [EGameLabel.CL]: 'Liga dos Campeões',
    [EGameLabel.RUSSIA]: 'Rússia',
    [EGameLabel.CONFERENCE_LEAGUE]: 'Conference League',
    [EGameLabel.EUROPA_LEAGUE]: 'Europa League',
  },
  quizes: {
    'correct-answer-label': 'Sim, a resposta está correta',
    'wrong-answer-label': 'Resposta incorreta!',
    'skip-label': 'Ignorar',
    'try-again-label': 'Tentar novamente',
  },
  'common.yes': 'Sim',
  'common.no': 'Não',
  'common.select-the': 'Selecionar',
  'common.tap-on-the': 'Toque em Pontuação',
  'common.tap-here': 'Toque aqui',
  'cjm-onboarding': {
    'warning-title': 'Tem certeza de que deseja continuar sem conhecer o sistema de pontuação?',
    welcome: {
      title: 'Bem-vindo ao seu primeiro torneio',
      action: 'Selecione o atacante',
      description: ' marcará mais pontos na partida de futebol real e ganhe prêmios!',
    },
    how: {
      title: 'Como os jogadores ganham pontos?',
    },
    'captain-instructions': {
      title: 'Parabéns!',
      'description-part-1': ` Você escalou toda a equipe. Selecione um capitão clicando no « `,
      'description-highlighted-part-1': 'ícone C',
      'description-part-2': '». O capitão multiplica os pontos por ',
      'description-highlighted-part-2': '1.2X',
    },
    quizes: {
      forward: {
        questions: {
          first: `Quantos pontos o atacante obterá se ele marcar 2 gols?`,
          second: `Quantos pontos o atacante ganha se der 2 assistências?`,
          third: `Quantos pontos o atacante ganha se acertar 2 chutes no gol?`,
        },
      },
    },
    'forward-label-pt-1': 'Se o atacante marcar',
    'forward-label-pt-2': '2 gols na partida do mundo real',
    'forward-description-pt-1': 'Ele obterá',
    'forward-description-pt-2': '140 pts',
    'forward-action': 'Entendi',
  },
  'scroing.groups': {
    'main-score': 'Pontuação principal',
    passes: 'Passes',
    defensive: 'Defensivo',
    penalty: 'Pênaltis',
    extra: 'Extra',
  },
  'scoring.for-every-{{n}}-minutes-in-the-match': 'Para cada {{n}} minutos de jogo',
  'scoring.for-every-{{n}}-passes-in-the-match': 'Para cada {{n}} passes na partida',
  'common.position-scoring': 'Pontuação de {{position}}',
  'position-details.reserve.description':
    'Substituição - seguro para o caso de um de seus principais jogadores não entrar em campo na partida. Nesse caso, em vez do jogador que não jogou, você receberá pontos do seu jogador substituto, mas com uma penalidade de 70% dos pontos.',
  'common.positions': {
    goalkeeper: 'Goleiro',
    forward: 'Atacante',
    midfielder: 'Meia',
    defender: 'Defensor',
    reserve: 'Reserva',
    back: 'Defensor',
    midfield: 'Meia',
    BACK: 'DEFENSOR',
    FORWARD: 'ATACANTE',
    GOALKEEPER: 'GOLEIRO',
    MIDFIELD: 'MEIA',
    RESERVE: 'RESERVA',
  },
  onboarding: {
    items: {
      welcome: {
        header: 'Boas vindas ao Fanton \nFantasy Football!',
        description: 'Ganhe dinheiro com sua paixão pelo futebol!',
      },
      team: {
        header: 'Monte o seu time',
        description: 'Escolha cinco jogadores de futebol para cada rodada de suas ligas favoritas',
      },
      leaderboard: {
        header: 'Jogue os torneios',
        description: 'Compita com outros jogadores',
      },
      'card-with-coins': {
        header: 'Ganhe dinheiro!',
        description: 'Comece seu primeiro torneio agora!',
      },
      'real-world': {
        header: 'Pontuação baseada no desempenho real dos jogadores',
        'real-world': 'real world',
        goal: 'gol',
        goals: 'Gols',
        assist: 'Assistências',
        yellow_card: 'Cartão amarelo',
        time_played: 'Tempo jogado',
        and_more: 'And more...',
      },
    },
    buttons: {
      skip: 'Pular, já sei!',
      continue: 'Continiue',
      play: 'Iniciar torneio',
    },
  },
  'premium-onboarding': {
    items: {
      'hold-on': {
        header: 'Pera aí! Você pode ganhar $400 em nosso torneio Premium',
        description: 'Topa este desafio?',
      },
      'want-to-win-this-prize': {
        header: 'Quer disputar essas premiações?',
        description: 'Jogue nosso torneio Premium!',
      },
    },
    buttons: {
      'more-info': 'Mais info',
      'play-premium': 'Jogar Premium',
      decline: 'No',
    },
  },
  'pvp-promo': {
    label: 'Troféus em duelos PvP',
    description: 'Batalhe em duelos, ganhe troféus e tokens $FTON',
    action: 'Play PVP',
  },
  'tonstarter-promo': {
    first: {
      header: 'Oferta Exclusiva',
      description: 'Inicie o Fanton no Telegram e ganhe ingressos para o Torneio Premium GRATUITAMENTE',
      action: 'Ganhe tickets',
    },
    second: {
      header: 'Premiações num total de $ 30.000 te esperam',
      description: 'Incentivo Fanton x Tonstarter',
      action: 'Entre agora',
    },
    third: {
      header: '$30 000 PRIZE POOL OF $FTON TOKEN',
      action: 'Entre agora',
    },
    'open-league': {
      header: 'THE OPEN LEAGUE',
      description: 'Complete a Fanton Quest e obtenha NFT Soulbound memoráveis',
      action: 'Join',
    },
  },
  'single-match-filters': {
    'main-events': 'Rodadas',
    'single-games': 'Partidas únicas',
  },
  'player-stat-values': {
    assist: 'Assist',
    'accurate-passes': 'Accurate passes',
    'accurate-crosses': 'Accurate crosses',
    'big-chances-created': 'Big chances created',
    'big-chances-missed': 'Big chances missed',
    'blocked-shots': 'Blocked shots',
    cleansheet: 'Cleansheet',
    clearances: 'Clearances',
    'clearance-offline': 'Clearance offline',
    'error-lead-to-goal': 'Error lead to goal',
    'fouls-drawn': 'Fouls drawn',
    fouls: 'Fouls',
    goals: 'Goals',
    'goals-conceded': 'Goals conceded',
    'hit-woodwork': 'Hit woodwork',
    interceptions: 'Interceptions',
    'key-passes': 'Key passes',
    'long-balls-won': 'Long ball won',
    'minutes-played': 'Minutes played',
    'miss-pass': 'Miss pass',
    'own-goals': 'Own goals',
    'penalties-won': 'Penalties won',
    'penalties-saved': 'Penalties saved',
    'penalties-misses': 'Penalties misses',
    'penalties-committed': 'Penalties committed',
    punches: 'Punches',
    'red-cards': 'Red cards',
    saves: 'Saves',
    'saves-inside-box': 'Saves inside box',
    'show-on-target': 'Shot on target',
    'successful-dribbles': 'Successful dribbles',
    trackles: 'Tackles',
    'average-points': 'Pontuação média',
    'yellow-cards': 'Yellow cards',
  },
  'player-detailed-info.stats-for-last-n-matches': 'Estatísticas das últimas {{n, number}} partidas',
  'common.actions.show-more': 'Mostrar mais',
  'common.actions.hide': 'Hide',
  'common.actions.dont-show-again': 'Não mostrar mais isso',
  'no-games-in-league-placeholder.label': 'Sem torneios nesta Liga',
  'no-games-in-league-placeholder.description': 'Escolha uma Liga diferente',
  'common.actions.create-new-team': 'Criar uma nova equipe',
  'common.actions.play-premium': 'Jogar Premium',
  'common-team-n': 'Equipe {{n}}',
  'common.errors.system-error': 'Algo deu errado. Tente novamente mais tarde ou entre em contato com o suporte.',
  'wallet.withdrawal-request-created.message': 'Sua solicitação de saque está pendente',
  'wallet-page.title': 'Saldo',
  'wallet-page.total': 'Balanço total',
  'wallet-page.receive': 'Receber',
  'wallet-page.send': 'Enviar',
  'wallet-page.swap': 'Câmbio',
  'wallet-page.not-enough-money-msg': 'Fundos insuficientes, recarregue seu saldo',
  'wallet-page.no-transactions-placeholder': 'Suas transações aparecerão aqui',
  'wallet-page.tickets.title': 'Meus ingressos',
  'wallet-page.transactions.title': 'Histórico de transações',
  'wallet-page.tokens.change-to': 'Trocar por',
  'transactions-list.today': 'Hoje',
  'transactions-list.yesterday': 'Ontem',
  'select-deposit-method.title': 'Select deposit method',
  'enter-amount-page.title': 'Insira o valor em TON',
  'common.continue-btn.label': 'Continuar',
  'common.score': 'Score',
  'common.dates': 'Datas',
  'enter-amount-page.commission': 'comissão',
  'enter-amount.no-commission': 'Sem comissão',
  'select-wallet-page.title': 'Select carteira',
  'select-wallet-page.card-top-up-info':
    'Seu saldo será reposto pelo preço do ticket. Se o valor do depósito for maior do que o custo do torneio, no futuro você poderá sacar esse valor ou pagar por outro torneio. Todos os provedores têm valores mínimos de depósito diferentes.',
  'bitcoin-transaction-lasting':
    'A confirmação do pagamento na rede BTC pode levar até 30 minutos. Depois que a transação for totalmente confirmada pela rede BTC, o saldo do seu jogo será reabastecido',
  'other-wallet-transfer-page.title': 'Confirmação de pagamento',
  'other-wallet-transfer-page.instruction':
    'Para recarregar o saldo do jogo, copie os campos e cole-os no aplicativo de sua carteira',
  'other-wallet-transfer-page.instruction.telegram-stars':
    'Em breve você poderá recarregar seu saldo Fanton usando a moeda Telegram Stars. Estamos trabalhando nesta opção para todos os jogadores.',
  'other-wallet-transfer-page.amount-in': 'Valor em',
  'other-wallet-transfer-page.wallet': 'Carteira',
  'other-wallet-transfer-page.comment': 'Comentário',
  'other-wallet-transfer-page.comment-instruction':
    'Copie este comentário e cole-o no campo de comentários da sua carteira para que possamos rastrear a transação',
  'other-wallet-transfer-page.return-to-balance': 'Retornar para o saldo',
  'select-withdrawal-wallet-page.wallets.wallet.label': 'Endereço da carteira',
  'withdrawal-request-created.placeholder.label': 'O pedido de saque foi criado',
  'withdrawal-request-created.placeholder.description':
    'O valor será enviado para sua carteira em até 24 horas. Nosso gerente entrará em contato com você em breve',
  'select-withdrawal-wallet-page.no-wallets.text': 'Connect wallet you want to withdraw to',
  'common.action.ok': 'OK',
  'transaction-operation.money-reward.label': 'Prize',
  'transaction-operation.game-ticket.label': 'Premium tournament',
  'transaction-status.created-label': 'Created',
  'transaction-status.approve_required-label': 'Waiting for approve',
  'transaction-status.approved-label': 'Approved',
  'transaction-status.started-label': 'Started',
  'transaction-status.completed-label.sent': 'Enviado',
  'transaction-status.completed-label.received': 'Recebido',
  'select-withdrawal-wallet-page.withdrawal-error': 'Parece que você não tem saldo suficiente em sua carteira',
  'wallets.other-wallet': 'Outra carteira',
  'neocrypto.payment-succeeded-page.placeholder.label': 'Success!',
  'neocrypto.payment-succeeded-page.placeholder.description-default':
    'We are delighted to inform you\nthat we received your payment',
  'waiting-for-transfer-page.placeholder.description-btc':
    'A confirmação do pagamento na rede BTC pode levar até 30 minutos. Depois que a transação for totalmente confirmada pela rede BTC, o saldo do seu jogo será reabastecido',
  'waiting-for-transfer-page.placeholder.description-default': 'Estamos aguardando a reposição do saldo',
  'waiting-for-transfer-page.placeholder.label': 'Aguardando a transferência',
  'prizes-claim': {
    'title.nft-tier': 'NFT Tier',
    description: {
      'special-card': 'Get special NFT player card',
      'card-avaliable-to': 'A carta estará disponível em',
      'will-be-sent-to-tonkeeper': 'Será enviado para a sua Tonkeeper',
      'get-5-cards': 'Get 5 player cards',
      'pack-avaliable-to': 'The pack will be available to',
      'pack-common-tier': 'Pack Common Tier',
      'connect-ton-wallet': 'Conecte sua TON wallet\npara conseguir resgatar seus eventuais prêmios',
      'will-be-credited-to-your-balance': 'Será acrescentado ao seu balanço',
      'ticket-to-x-tournament': 'Ticket para o torneio {{tournament}}',
      'will-be-sent-to-stonfi': `Será creditado em sua carteira. Aguarde os fundos chegarem`,
    },
    action: {
      'get-prize': 'Receber o prêmio',
    },
  },
  'common.place-plural_one': '{{count}}º lugar',
  'common.place-plural_two': '{{count}}º lugar',
  'common.place-plural_few': '{{count}}º lugar',
  'common.place-plural_other': '{{count}}º lugar',
  'common.search.placeholder': 'Buscar por um jogador',
  'common.game.statuses.paid': 'Pago',
  'common.game.statuses.in-progress': 'Em andamento',
  'common.game.statuses.completed': 'Concluído',
  'common.game.statuses.get-prize': 'Receber o prêmio',
  'common.game.statuses.join': 'Participar',
  'common.common': 'Comum',
  'common.nft': 'NFT',
  'common.tier': 'Tier',
  'common.ton-connect.sign-in-btn': 'Conectar a carteira',
  'common.free': 'Gratuito',
  'common.tickets': 'Tickets',
  'prizes.participiants-in-tournament': 'Participantes do torneio',
  'prizes.guaranteed-prize': 'Premiação',
  'prizes.info-about-participiants': 'Prizes are calculated from the number of participants in the tournament',
  'common.ratio': 'Proporção',
  'common.price': 'Entrada',
  'home.section.tournaments.header': 'TORNEIOS',
  'home.section.tournaments.all-tournaments': 'Todos os torneios',
  'home.section.tournaments.see-all-btn': 'Ver todos',
  'home.section.tournaments.no-games.label': 'Não há torneios programados',
  'home.section.my-tournaments.header': 'MEUS TORNEIOS',
  'home.section.my-tournaments.see-all-btn': 'Ver todos',
  'home.section.my-tournaments.placeholder.no-games.label': 'Você ainda não iniciou nenhum jogo',
  'home.section.my-tournaments.placeholder.label': 'Forme sua equipe para participar de torneios',
  'home.section.my-tournaments.placeholder.action': 'Participar no próximo torneio',
  'home.slider.prizes.see-all-btn': 'Ver todos',
  'home.slider.prizes.close-btn': 'Fechar',
  'home.tabs.main.label': 'Menu',
  'home.tabs.nft.label': 'NFT',
  'home.tabs.prizes.label': 'Prêmios',
  'home.tabs.support.label': 'Suporte',
  'prizelist.prizes.n-place.title': '{{n, number}}º lugar',
  'prizelist.prizes.n-m-places.title': '{{n, number}} - {{m}} places',
  'prizelist.prizes.top-n-%.title': 'Top {{n, number}}%',
  'prizelist.prizes.n-m-%.title': '{{n, number}}-{{m}}%',
  'prizelist.play-tournaments': 'Jogar nos torneios',
  'home.slider.nft-cards.header': 'CARTAS NFT',
  'home.slider.nft-cards.see-all-btn': 'Ver todos',
  'tournaments.header.title': 'Torneios',
  'common.tabs.paid.title': 'Premium',
  'games.tabs.current': 'Atual',
  'games.tabs.past': 'Finalizado',
  'cards.nft-cards.header': 'Cartas NFT',
  'cards.nft-cards.buy-cards-btn': 'Comprar cartas NFT',
  'cards.nft-cards.placeholder.label': 'Você não possui cartas NFT',
  'cards.nft-cards.placeholder.description': 'Compre a primeira',
  'cards.nft-cards.placeholder.action': 'Comprar carta NFT',
  'cards.tabs.common': 'Comum',
  'cards.tabs.nft': 'NFT',
  'cards.placeholder.description.hint': 'Tente novamente',
  'cards.placeholder.label': 'Carta não encontrada',
  'cards.card-advanced.player-team': 'Equipe do jogador',
  'cards.card-advanced.opposite-team': 'Equipe adversária',
  'cards.card-advanced.avg-pts-in-last-n': 'Média de pontos nos últimos {{n, number}} jogos',
  'notifications.header.title': 'Notificações',
  'notifications.placeholder.label': 'Nenhuma nova notificação',
  'notifications.placeholder.description': 'Você leu todas as\nmensagens',
  'notifications.notification.chip.get-prize': 'Receber o prêmio',
  'notifications.notification.chip.collect': 'Collect',
  'notifications.notification.default-name': 'Free common pack was awarded',
  'filters.filter.tier.label': 'Tier',
  'filters.filter.position.label': 'Posição do jogador',
  'filters.header.title': 'Filtrar',
  'filters.reset-btn.label': 'Redefinir tudo',
  'filters.filter.club.label': 'Clube',
  'filters.filter.country.label': 'País',
  'filters.filter.championship.label': 'Campeonato',
  'filters.aplly-btn.label': 'Aplicar',
  'filter.apply-btn.label': 'Aplicar',
  'filter.header.title': 'Filtrar',
  'games.placeholder.label': 'Torneios não encontrados',
  'games.placeholder.description-current': 'Escolha entre os torneios em andamento, ou\nretorne mais tarde.',
  'games.placeholder.description-past': 'Os torneios dos quais você participou\n aparecerão aqui',
  'games.placeholder.action': 'Participar em novo torneio',
  'game.tab.prizes': 'Prêmios',
  'game.tab.team': 'Equipe',
  'game.tab.matches': 'Jogos',
  'game.tab.results': 'Resultados',
  'game.participants': 'Participantes',
  'game.points-limit': 'Máxima total',
  'game.my-place': 'Lugar',
  'game.teams.duplicate-onboarding': {
    header: 'Duplicate team',
    description: 'Create a duplicate of the command',
    action: 'ОК',
  },
  'position-details.header': 'Info',
  'position-details.points-detail.goal': 'Gol (sem pênalti)',
  'position-details.points-detail.own-goal': 'Gol contra',
  'position-details.points-detail.assist': 'Assistência',
  'position-details.points-detail.match-without-missing-gt-60': 'Partida sem perder (>60 min)',
  'position-details.points-detail.match-without-missing-lt-60': 'Partida sem perder (<60 min)',
  'position-details.points-detail.earned-penalty': 'Sofreu um pênalti',
  'position-details.points-detail.penalty-scored': 'Pênalti convertido',
  'position-details.points-detail.penalty-missed': 'Pênalti não convertido',
  'position-details.points-detail.penalty-saved': 'Pênalti defendido',
  'position-details.points-detail.fouled-penalty': 'Cometeu um pênalti',
  'position-details.points-detail.match-participation': 'Participação na partida',
  'position-details.points-detail.player-on-field-gt-60': 'Player on the field (>60 min)',
  'position-details.points-detail.first-yellow-card': 'Primeiro cartão amarelo',
  'position-details.points-detail.second-yellow-card': 'Segundo cartão amarelo',
  'position-details.points-detail.direct-red-card': 'Cartão vermelho direto',
  'position-details.points-detail.team-win': 'Vitória da equipe',
  'position-details.points-detail.team-draw': 'Empate da equipe',
  'position-details.points-detail.team-loss': 'Derrota da equipe',
  // V3 Scoring system
  'position-details.points-detail.GOALS': 'Gols',
  'position-details.points-detail.ASSISTS': 'Assistências ',
  'position-details.points-detail.ACCURATE_PASSES': 'Passes certos',
  'position-details.points-detail.ACCURATE_CROSSES': 'Cruzamentos certos',
  'position-details.points-detail.KEY_PASSES': 'Passes decisivos',
  'position-details.points-detail.MISS_PASS': 'Passes errados',
  'position-details.points-detail.FOULS_DRAWN': 'Faltas cavadas',
  'position-details.points-detail.FOULS': 'Faltas',
  'position-details.points-detail.SAVES': 'Defesas',
  'position-details.points-detail.SAVES_INSIDE_BOX': 'Defesas na pequena área',
  'position-details.points-detail.PUNCHES': 'Espalmadas',
  'position-details.points-detail.CLEANSHEET': 'Não tomou gol',
  'position-details.points-detail.MINUTES_PLAYED': 'Minutos jogados',
  'position-details.points-detail.INTERCEPTIONS': 'Intercepções',
  'position-details.points-detail.CLEARANCES': 'Chutões',
  'position-details.points-detail.TACKLES': 'Desarmes',
  'position-details.points-detail.SHOTS_ON_TARGET': 'Chutes no gol',
  'position-details.points-detail.BLOCKED_SHOTS': 'Chutes travados',
  'position-details.points-detail.HIT_WOODWORK': 'Chutes na trave',
  'position-details.points-detail.CLEARANCE_OFFLINE': 'Chutões da linha de fundo',
  'position-details.points-detail.SUCCESSFUL_DRIBBLES': 'Dribles certos',
  'position-details.points-detail.PENALTIES_MISSES': 'Pênaltis perdidos',
  'position-details.points-detail.PENALTIES_SAVED': 'Pênaltis defendidos',
  'position-details.points-detail.PENALTIES_WON': 'Pênaltis convertidos',
  'position-details.points-detail.PENALTIES_COMMITTED': 'Pênaltis causados',
  'position-details.points-detail.LONG_BALLS_WON': 'Passes longos rasteiros',
  'position-details.points-detail.REDCARDS': 'Cartões vermelhos',
  'position-details.points-detail.YELLOWCARDS': 'Cartões amarelos',
  'position-details.points-detail.OWN_GOALS': 'Gols-contra',
  'position-details.points-detail.ERROR_LEAD_TO_GOAL': 'Erro que levou a gol',
  'position-details.points-detail.GOALS_CONCEDED': 'Gols concedidos',
  'position-details.points-detail.BIG_CHANCES_MISSED': 'Chances claras perdidas',
  'position-details.points-detail.BIG_CHANCES_CREATED': 'Chances claras criadas',

  'position-details.reserve-player.description':
    'Recebe 70% dos pontos pela sua posição, caso algum jogador do elenco principal não tenha entrado em campo. É considerado o momento anterior da saída do jogador do campo.',
  'team.action-label.select': 'SELECIONAR',
  'team.position.goalkeeper': 'GR',
  'team.position.back': 'DEF',
  'team.position.midfield': 'MD',
  'team.position.forward': 'AV',
  'team.position.reserve': 'RES',
  'team.position.any': 'QUALQUER',
  'team.position.any-player': 'JOGADOR',
  'team.position.any-player.subtitle': 'Jogador',
  'team.position.all-players': 'QUALQUER JOGADOR',
  'team.payment-status.in-progress': 'Estamos aguardando a confirmação de\npagamento. Pode demorar até 10 minutos',
  'team.payment-status.success': 'Pagamento concluído',
  'team.nft-card-missing': 'Obtenha pelo menos uma carta NFT para\nparticipar do torneio NFT.',
  'team.select-any-player-header': 'Selecionar jogador',
  'team.select-at-least-n-rarity-cards': 'Selecione pelo menos {{n, number}} carta {{rarity}}',
  'team.select-up-to-n-rarity-cards': 'Selecione até {{n, number}} carta {{rarity}}',
  'team.rarity-cards-are-not-allowed': '{{rarity}} não são permitidos',
  'common.game': 'Torneio',
  'common.or': 'ou',
  team: {
    onboarding: {
      text: {
        begginerV2: '- Uso ilimitado de NFTs raros\n- Limite de 2 NFT Épico\n- Limite de 1 NFT Lendários',
        beginner: 'Para participar, você precisa de pelo menos um NFT de qualquer raridade',
        amateur: '- Uso ilimitado de NFTs raros\n- Limite de 1 NFT Épico\n- Não são permitidos NFTs Lendários',
        pro: '- Uso ilimitado de NFTs Raros, Common e épicos\n- Limite de 1 carta NFT Lendária',
        legendary: 'Para participar, selecione pelo menos \n2 NFTs Lendários',
      },
    },
  },
  'cards.market-status.filters.all-cards': 'Todas as cartas',
  'cards.market-status.filters.on-sale': 'À venda',
  'cards.market-status.filters.at-auction': 'Leilão',
  'cards.market-status.filters.not-for-sale': 'Não está à venda',
  'commmon.sort-options.by-price': 'Buscar por preços',
  'common.my-nft': 'Meus NFTs',
  'common.market': 'Mercado',
  'cards.actions.see-on-ggems': 'Compre no Getgems.io',
  'cards.actions.buy-on-ggems': 'See on Getgems.io',
  'filters.filter.rarity.label': 'Raridade',
  'filters.filter.price.min-price.label': 'Preço (min)',
  'filters.filter.price.max-price.label': 'Preço (max)',
  'filters.filter.price.enter-price.placeholder': 'Enter price',
  'team.captain-missing': 'Selecione o capitão para iniciar o torneio',
  'position-details.captain': 'Capitão',
  'team.too-much-players-from-same-team':
    'Você não pode inscrever uma equipe com mais de {{n, number}} jogadores do mesmo clube de futebol. Por favor, mude a escalação.',
  'team.too-much-points': 'Soma dos pontos dos jogadores excede a pontuação total. Troque os jogadores',
  'team.pop-up.auth-required': 'Inicie a sessão para participar do torneio',
  'game.actions.pay-in-currency': 'Pagar {{n, number}} {{c}}',
  'game.actions.play-with-this-team': 'Iniciar o torneio',
  'game.actions.save-changes': 'Salvar alterações',
  'game.actions.placeholder.completed': 'A rodada já encerrou.',
  'game.actions.placeholder.in-progress.line-1': 'Não é mais possível fazer alterações na',
  'game.actions.placeholder.in-progress.line-2': 'equipe. A rodada terminará em:',
  'game.results.placeholder.no-started.label': 'Não tão rápido',
  'game.results.placeholder.no-started.description':
    'Os resultados estarão disponíveis após o final\nda semana do jogo',
  'game.results.placeholder.no-teams.label': 'Nenhuma equipe registrada.',
  'game.results.placeholder.no-teams.description': 'Esta rodada não tem participantes.',
  'game.results.your-position': 'Você',
  'game.results.your-place-is': 'Sua posição',
  'game.results.out-m': 'de {{m}}',
  'common.scoring-system': 'Sistema de pontuação',
  'game.results.points-label': 'pts',
  'game.prizes.placeholder.no-prize.label-top-n-pct': 'Incrível,\nvocê entrou no top {{n, number}}%',
  'game.prizes.placeholder.no-prize.description-n-teams-beaten': 'E derrotou {{n}} times',
  'game.prizes.placeholder.no-prize.same-n-usd-prize-in-premium':
    'Com a mesma equipe, você poderia ter ganho ${{n}} em nosso Torneio Premium',
  'game.prizes.placeholder.no-prizes.action': 'Jogar novamente',
  'game.prizes.placeholder.prizes-claimed.label': 'O prêmio foi entregue!',
  'game.prizes.placeholder.prizes-ready.label.line-1': 'Parabéns!',
  'game.prizes.placeholder.prizes-ready.label.line-2': `Você terminou em`,
  'game.prizes.placeholder.prizes-ready.action': 'Receber o prêmio',
  'game.prizes.placeholder.prizes-claimed.action': 'Ir para o menu',
  'game.prizes.placeholder.prizes-claimed.description.line-1': 'Teste sua equipe nos próximos torneios',
  'game.prizes.placeholder.prizes-claimed.description.line-2': '',
  'game.prizes.placeholder.prizes-ready.description': 'Ganhe o prêmio!',
  'team-created.placeholder.label': 'Você está inscrito para o torneio',
  'team-created.placeholder.action': 'OK',
  'team-created.placeholder.duplicate-to-premium': 'Duplicar time no torneio Premium',
  'team-created.placeholder.duplicate-team': 'Duplicar escalação',
  'team-created.placeholder.back-to-menu': 'Voltar para tela inicial',
  'pack-claimed.placeholder.label': 'O prêmio foi entregue',
  'pack-claimed.placeholder.description.line-1': 'Você já coletou as cartas desta rodada',
  'pack-claimed.placeholder.description.line-2': '',
  'pack-claimed.placeholder.action': 'Watch my cards',
  'team-details.total-points.titile': 'Total de pontos na rodada',
  'team-details.total-points.points-label': 'pts',
  'team-details.player.action.title': 'Ações do jogador',
  'team-details.player.action.not-applicable': 'Não aplicável',
  'community-thumb.label': 'COMUNIDADE',
  'community-thumb.btn.label': 'Participar',
  'prizes.header.title': 'Prêmios',
  'support.label': 'Suporte',
  'support.placeholder.label': 'Entrar em contato com o suporte',
  'support.placeholder.description': 'Para entrar em contato com o serviço de suporte, você sairá do jogo',
  'support.placeholder.action': 'Contatar o suporte',
  'referral-program': {
    header: 'Convide um amigo',
    invited: 'Convidados',
    played: 'Jogaram',
    tickets: 'Tickets',
    friends: 'Amigos',
    'awarded-tickets': 'Ticket concedido',
    actions: {
      share: 'Compartilhar',
      'copy-to-friends': 'Copie o link e mande para seus amigos',
    },
  },
  'common.copied': 'Copiado',
  'telegram-wallet-info': '«Telegram Wallet» - carteira oficial do Telegram',
  'balance-repenisment-ton': {
    header: 'Reposição de saldo',
    actions: {
      deposit: 'Depósito',
      'deposit-in-telegram-wallet': 'Depósito na Telegram Wallet',
      'pay-another-cryptocurrencies': 'Pagar com outras criptomoedas',
    },
  },
  'friends-games': {
    header: 'Jogar com amigos',
    'play-with-friends': 'Jogar com amigos',
    'my-tournaments': 'Meus torneios',
    'by-invitation': 'Por convite',
    'create-new': 'Criar novo torneio',
    'mine-placeholder': {
      description: 'Os torneios criados por você aparecerão aqui',
    },
    'invited-placeholder': {
      header: 'Torneios por convite',
      description: 'Os torneios para os quais você foi convidado, aparecerão aqui',
    },
    'past-placeholder': {
      header: 'Torneios anteriores',
      description: 'Os torneios que você jogou com seus amigos aparecerão aqui',
    },
    banner: {
      label: 'JOGAR COM AMIGOS',
      description: 'Crie torneios e jogue Futebol Fantasia com seus amigos',
      action: 'Jogar',
    },
  },
  'create-game': {
    header: 'Escolher torneio',
    info: 'Você pode criar apenas um torneio em cada liga',
  },
  'common.create': 'Criar',
  'game-created': {
    header: 'Parabéns, você criou um torneio',
    description: 'Crie sua equipe e convide seus amigos para competir com você.',
    action: 'Criar equipe',
  },
  'invite-friends': 'Сonvide amigos',
  pvp: {
    homeScreenLinkLabel: 'Partida PvP',
    label: 'Partida PvP',
    myPvp: 'Meu PvP',
    pvp: 'PvP',
  },
  'common.firstPlace': '1º lugar',
  leaderboars: {
    label: 'Classificação',
    levels: {
      amateur: 'Amateur',
      pro: 'Pro',
      worldClub: 'Clube mundial',
    },
    nextLevel: 'Próximo nível',
    level: 'Nível',
    league: 'Liga',
    seasons: 'Temporadas',
    howToEarnTrophies: 'Como ganhar troféus?',
    currentPts: 'Troféus atuais',
    myPts: 'Meus Troféus',
    myPrize: 'Meu prêmio',
    leaders: 'Líderes',
    trophies: 'Trophies',
    trophies_count_other: 'Trophies',
    noParticipantsPlaceholder: {
      labelPt1: 'Você precisa',
      labelPt2: 'para entrar nesta liga',
      description: 'Jogue mais torneios para avançar para esta liga',
    },
    seasonOverPlaceholder: {
      label: 'A {{name}} acabou',
      description: 'É hora de receber os prêmios',
    },
  },
  'team.captain-not-nft-in-nft-game': 'Solo una carta NFT può essere selezionata come capitano',
  tasks: {
    'to-play-in-tournament-with-garantee': 'Para jogar o torneio com premiação de {{s}}',
    'to-play-in-tournament': 'Para jogar o torneio',
    start: 'Start',
    'check-status': 'Estado de verificação',
    continue: 'Começar as tarefas',
    'start-over': 'Começar as tarefas',
    'complete-tasks': 'Completar tarefas',
  },
  'ido-promo.action': 'Junte-se',
  'ido-promo.label': 'BEM-VINDO AO $FTON',
  'ido-promo.description':
    'Participe da campanha para ganhar 85.000 dólares e inscreva-se na whitelist de $FTON na IDO',
  'common.lootbox': 'Lootbox',
  'no-lootboxes-placeholder': {
    label: 'Lootboxes are not available',
    description: 'We will add them soon',
  },
  'lootboxes-cards-available': 'Carta disponível',
  'lootboxes-box-avaliable-till': 'Box disponível até',
  'lootboxes-buy': 'Comprar por {{amount}} {{currency}}',
  'lootboxes-chance': 'Chance',
  'get-card': 'Obter carta',
  'prize-pool': 'Prize pool',
  'euro2024promo.label': '@WHALE EURO2024 PRIZE POOL $100 000',
  'tickets-to-the': 'Tickets to the',
  'next-round': 'next round',
  leaderboards: 'Leaderboards',
  'how-to-play-winline': 'How to play Winline tournament',
  'winline-tournaments': 'Tournaments',
  'playoff-prize': 'PRIZE',
  'register-team-with-task': 'Task',
  'euro-details': 'Details',
  'euro-n-percents-get-tikcet': '{{n}}% dos melhores times conseguem tickets para a rodada seguinte',
  'euro-1-n-get-ticker': '1-{{n}} lugares conseguem tickets para a rodada seguinte',
  'ticket-to-next-round': 'Ticket para a próxima rodada',
  'whale-cashback-promo': {
    label: 'Jogue a EURO no @WHALE',
    description: 'Aposte na EURO-2024 e obtenha até 30% de cashback no @Whale',
  },
  'euro-teams': 'Equipe',
  'euro-no-prize': 'Não há prêmios',
};

export default PTTranslation;
