/** функция слияния рутов */
function pathJoin(...parts) {
  const separator = '/';
  const replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

/** получение параметров точки доступа сервера */
export function getUriSource() {
  if (process.env.NODE_ENV !== 'development') return pathJoin(process.env.PUBLIC_URL, '/graphql');

  return '/graphql';
}
