import { EGameLabel } from '@Global/consts';
import { TFantonAppTranslation } from '@Global/i18n';

const ENTranslation: TFantonAppTranslation = {
  'common.stop': 'Stop',
  roultette: {
    'start-game': 'Spin',
  },
  'daily-roulette': {
    button: 'Daily prize',
    title: 'Time to roll',
    description: 'Spin the wheel to win a daily prize',
    prizes: {
      'you-won-ticket-to-name-tournament': `You've won Ticket for {{name}} Tournament`,
      'you-won-prize': `You've won {{prize}}`,
      'type-nft-avaliable-for-n-hours': '{{type}} NFT is available to you for {{n}} days',
    },
    timeout: 'The next spin is through:',
    nothing: {
      title: 'Nothing today..',
      description: 'Better luck next time',
    },
  },
  'any-player-onboarding': {
    label: 'New Single matches',
    description: 'Now, you have the option to select any player positions in Single matches',
  },
  'community-onboarding': {
    label: 'Join our community',
    description: `Don't miss out on the latest Fanton updates, tournaments, and promotions!`,
    actions: {
      skip: 'Skip',
      join: 'Join',
    },
  },
  'filters.advanced-settings.label': 'Advanced setting',
  'filters.advanced-settings.reset-btn.label': 'Reset settings',
  'common.statistics-for-the-last-n-matches': 'Statistics for the last {{n}} matches',
  'common.average-for-the-match': 'Average for the match',
  'common.min': 'min',
  'common.points': 'Points',
  'common.tournaments.labels': {
    [EGameLabel.EPL]: 'England',
    [EGameLabel.LALIGA]: 'Spain',
    [EGameLabel.LIGA1]: 'France',
    [EGameLabel.BUNDESLIGA]: 'Germany',
    [EGameLabel.SERIE_A]: 'Italy',
    [EGameLabel.BRA1]: 'Brazil',
    [EGameLabel.TOP5]: 'TOP-5 United',
    [EGameLabel.WC2022]: 'Qatar 2022',
    [EGameLabel.EURO2024]: 'Euro 2024',
    [EGameLabel.CL]: 'Champions League',
    [EGameLabel.RUSSIA]: 'Russia',
    [EGameLabel.CONFERENCE_LEAGUE]: 'Conference League',
    [EGameLabel.EUROPA_LEAGUE]: 'Europa League',
    [EGameLabel.MLS]: 'USA',
  },
  quizes: {
    'correct-answer-label': 'Correct!',
    'wrong-answer-label': 'Incorrect answer',
    'skip-label': 'Skip',
    'try-again-label': 'Try again',
  },
  'common.yes': 'yes',
  'common.no': 'no',
  'common.select-the': 'Select the',
  'common.tap-on-the': 'Tap on the',
  'common.tap-here': 'Tap here',
  'common.out-of': 'out of',
  'cjm-onboarding': {
    'warning-title': 'Are you sure you want to continue without knowing the scoring system?',
    welcome: {
      title: 'Welcome to your first tournament',
      action: 'Select the forward',
      description: ' who will score more points in the real football match and win prize!',
    },
    how: {
      title: 'How do players earn points?',
    },
    'captain-instructions': {
      title: 'Congratulations!',
      'description-part-1': " You've gathered the whole team. Select a captain by clicking on the «",
      'description-highlighted-part-1': 'C icon',
      'description-part-2': '». The captain multiplies points by ',
      'description-highlighted-part-2': '1.2X',
    },
    quizes: {
      forward: {
        questions: {
          first: 'How many points will the attacker get if he scores 2 goals?',
          second: 'How many points the forward get if he gives 2 assists?',
          third: 'How many points the forward get if he makes 2 shots on target?',
        },
      },
    },
    'forward-label-pt-1': 'If forward score',
    'forward-label-pt-2': '2 goals in real football match',
    'forward-description-pt-1': 'He will get',
    'forward-description-pt-2': '140 pts',
    'forward-action': 'I understand',
  },
  'scroing.groups': {
    'main-score': 'Main score',
    passes: 'Passes',
    defensive: 'Defensive',
    penalty: 'Penalty',
    extra: 'Extra',
  },
  'scoring.for-every-{{n}}-minutes-in-the-match': 'For every {{n}} minutes in the match',
  'scoring.for-every-{{n}}-passes-in-the-match': 'For every {{n}} passes in the match',
  'common.position-scoring': '{{position}} scoring',
  'position-details.reserve.description':
    'Replacement - insurance in case one of your main players doesn’t take the field in the match. In this case, instead of the player who didn’t play, you will receive points from your substitute player, but with a penalty of 70% of the points',
  'common.positions': {
    goalkeeper: 'Goalkeeper',
    forward: 'Forward',
    midfielder: 'Midfielder',
    defender: 'Defender',
    reserve: 'Reserve',
    back: 'Defender',
    midfield: 'Midfielder',
    BACK: 'BACK',
    FORWARD: 'FORWARD',
    GOALKEEPER: 'GOALKEEPER',
    MIDFIELD: 'MIDFIELD',
    RESERVE: 'RESERVE',
  },
  onboarding: {
    items: {
      welcome: {
        header: 'Welcome to Fanton \nFantasy Football!',
        description: 'Earn money with your passion for football',
      },
      team: {
        header: 'Assemble a team',
        description: 'Choose five football players for each matchday \nof your favorite football leagues',
      },
      leaderboard: {
        header: 'Play tournaments',
        description: 'And compeat with other players',
      },
      'card-with-coins': {
        header: 'Win real money!',
        description: 'Start your first tournament now',
      },
      'real-world': {
        header: 'Get points for the real world performance of your players',
        'real-world': 'real world',
        goal: 'Goal',
        goals: 'Goals',
        assist: 'Assist',
        yellow_card: 'Yellow card',
        time_played: 'Time played',
        and_more: 'And more...',
      },
    },
    buttons: {
      skip: 'Skip, I’m proffi',
      continue: 'Continiue',
      play: 'Start tournament',
    },
  },
  'premium-onboarding': {
    items: {
      'hold-on': {
        header: 'Hold on! You can win $400 in our premium Tournament',
        description: 'Dare to take the challenge?',
      },
      'want-to-win-this-prize': {
        header: 'Want to win this prize?',
        description: 'Premium tournament now!',
      },
    },
    buttons: {
      'more-info': 'More info',
      'play-premium': 'Play premium',
      decline: 'No',
    },
  },
  'pvp-promo': {
    label: 'Trophies in PvP Duels',
    description: 'Battle in Duels, earn Trophies and $FTON tokens',
    action: 'Play PVP',
  },
  'okx-promo': {
    label: 'Connect OKX Wallet and share 800k $FTON',
    description: '',
    action: 'Join',
  },
  'tonstarter-promo': {
    first: {
      header: 'Exclusive offer',
      description: 'Launch Fanton on Telegram and get Premium Tournament ticket for FREE',
      action: 'Get ticket',
    },
    second: {
      header: '$30 000 prize pool awaits',
      description: 'Fanton x Tonstarter incentive',
      action: 'Join now',
    },
    third: {
      header: '$30 000 PRIZE POOL OF $FTON TOKEN',
      description: 'Fanton x Tonstarter Airdrop',
      action: 'Join now',
    },
    'open-league': {
      header: 'THE OPEN LEAGUE',
      description: 'Complete Fanton Quest and get memorable Soulbound NFT',
      action: 'Join',
    },
  },
  'single-match-filters': {
    'main-events': 'Main events',
    'single-games': 'Single match',
  },
  'player-stat-values': {
    assist: 'Assist',
    passes: 'Passes',
    'accurate-passes': 'Accurate passes',
    'dribbled-attempts': 'Dribble attemps',
    'accurate-crosses': 'Accurate crosses',
    'big-chances-created': 'Big chances created',
    'big-chances-missed': 'Big chances missed',
    'blocked-shots': 'Blocked shots',
    cleansheet: 'Cleansheet',
    clearances: 'Clearances',
    'clearance-offline': 'Clearance offline',
    'error-lead-to-goal': 'Error lead to goal',
    'fouls-drawn': 'Fouls drawn',
    fouls: 'Fouls',
    goals: 'Goals',
    'goals-conceded': 'Goals conceded',
    'hit-woodwork': 'Hit woodwork',
    interceptions: 'Interceptions',
    'key-passes': 'Key passes',
    'long-balls-won': 'Long ball won',
    'minutes-played': 'Minutes played',
    'miss-pass': 'Miss pass',
    'own-goals': 'Own goals',
    'penalties-won': 'Penalties won',
    'penalties-saved': 'Penalties saved',
    'penalties-misses': 'Penalties misses',
    'penalties-committed': 'Penalties committed',
    punches: 'Punches',
    'red-cards': 'Red cards',
    saves: 'Saves',
    'saves-inside-box': 'Saves inside box',
    'show-on-target': 'Shot on target',
    'successful-dribbles': 'Successful dribbles',
    trackles: 'Tackles',
    'average-points': 'Average points',
    'yellow-cards': 'Yellow cards',
  },
  'player-detailed-info.stats-for-last-n-matches': 'Statistics for the last {{n, number}} matches',
  'common.all': 'All',
  'common.or': 'or',
  'common.score': 'Score',
  'common.dates': 'Dates',
  'common.actions.show-more': 'Show more',
  'common.actions.hide': 'Hide',
  'common.actions.dont-show-again': "Don't show anymore",
  'no-games-in-league-placeholder.label': 'No tournaments in this league',
  'no-games-in-league-placeholder.description': 'Choose a different one',
  'common.actions.create-new-team': 'Create new team',
  'common.actions.play-premium': 'Play premium',
  'common-team-n': 'Team {{n}}',
  'common.errors.system-error': 'Something went wrong. Try again later or contact support.',
  'common.stake.bronze': 'Bronze',
  'common.stake.silver': 'Silver',
  'common.stake.gold': 'Gold',
  'common.stake.platinum': 'Platinum',
  'wallet-page.title': 'Balance',
  'wallet-page.total': 'Total balance',
  'wallet-page.receive': 'Receive',
  'wallet-page.send': 'Send',
  'wallet-page.swap': 'Swap',
  'wallet-page.stake': 'Stake',
  'wallet-page.not-enough-money-msg': 'Insufficient funds, top up your balance',
  'wallet-page.no-transactions-placeholder': 'Your transactions will appear here',
  'wallet-page.tickets.title': 'My tickets',
  'wallet-page.tokens.title': 'My tokens',
  'wallet-page.stakes.title': 'My stakes',
  'wallet-page.stakes.until': 'Until',
  'wallet-page.transactions.title': 'Transaction history',
  'wallet-page.tokens.change-to': 'Change to',
  'transactions-list.today': 'Today',
  'transactions-list.yesterday': 'Yesterday',
  'swap-money-page.title': 'Swap',
  'swap-money-page.fields.you-send': 'You send',
  'swap-money-page.fields.you-receive': 'You receive',
  'swap-money-page.fee': 'Fee',
  'swap-money-page.blockchain-fee': 'Blockchain fee',
  'swap-money-page.footnote.provided-by': 'Provided by',
  'swap-money-page.footnote.privacy-policy': 'Privacy Policy',
  'swap-money-page.footnote.terms-of-use': 'Terms of Use',
  'swap-money-page.connect-wallet-placeholder.description': 'To continue the operation, connect your wallet',
  'stake-money-page.title': 'Stake',
  'stake-money-page.radio.days': '{{n}} days',
  'stake-money-page.stake-class': 'Class',
  'stake-money-page.minimum-deposit': 'Minimum deposit',
  'stake-money-page.earn-up-to': 'Earn up to',
  'stake-money-page.fields.you-stake': 'You stake',
  'stake-money-page.fields.total-stake': 'Total stake',
  'stake-money-page.fields.apy': 'Annual Percentage Yield (APY)',
  'stake-money-page.fields.daily-accruals': 'Daily accruals',
  'stake-money-page.fields.claim-date': 'Claim date',
  'stake-money-page.fields.expected-income': 'Expected income',
  'stake-money-page.fields.withdraw-note': 'Funds can be withdrawn no earlier than сlaim date',
  'stake-created.placeholder.description': "You've created {{c}} stake.",
  'stake-money-page.warnings.min-stake': 'Minimum stake class require {{d}} {{c}}',
  'select-deposit-method.title': 'Select deposit method',
  'enter-amount-page.title': 'Enter amount in TON',
  'common.continue-btn.label': 'Continue',
  'common.confirm-btn.label': 'Confirm',
  'enter-amount-page.commission': 'commission',
  'enter-amount.no-commission': 'No commission',
  'enter-amount-in-currency.errors.not-enough-coins': 'Not enough coins',
  'enter-amount-in-currency.errors.insufficient-balance': 'Insufficient {{c}} balance',
  'enter-amount-in-currency.errors.daily-limit': '{{amount}} {{currency}} is the max amount per day',
  'select-wallet-page.title': 'Select wallet',
  'select-wallet-page.card-top-up-info':
    'Your balance will be replenished by the ticket price. If the deposit amount would be bigger than the cost of the tournament, then in the future you will be able to withdraw this amount or pay for another tournament. All providers have different minimum deposit amounts.',
  'bitcoin-transaction-lasting':
    'Payment confirmation in the BTC network can take up to 30 minutes. After the transaction is fully confirmed by the BTC network, your game balance will be replenished.',
  'other-wallet-transfer-page.title': 'Payment Confirmation',
  'other-wallet-transfer-page.instruction':
    'To top up the game balance, copy the fields and paste them in the application of your wallet',
  'other-wallet-transfer-page.instruction.telegram-stars':
    'Soon you will be able to top up your Fanton balance using Telegram Stars currency. We are working on this option for all players.',
  'other-wallet-transfer-page.amount-in': 'Amount in',
  'other-wallet-transfer-page.wallet': 'Wallet',
  'other-wallet-transfer-page.comment': 'Comment',
  'other-wallet-transfer-page.comment-instruction':
    'Copy this comment and paste it into the comment field in your wallet so that we can track the transaction',
  'other-wallet-transfer-page.return-to-balance': 'Return to balance',
  'select-withdrawal-wallet-page.wallets.wallet.label': 'Wallet adress',
  'withdrawal-request-created.placeholder.label': 'The withdrawal request has been created',
  'withdrawal-request-created.placeholder.description':
    'The amount will be sent to your wallet within 24 hours. Our manager will contact you soon',
  'swap-request-created.placeholder.description': 'Has been sent to your balance',
  'tg-stars.placeholder.label': 'Telegram Stars',
  'select-withdrawal-wallet-page.no-wallets.text': 'Connect wallet you want to withdraw to',
  'common.action.ok': 'OK',
  'transaction-operation.money-reward.label': 'Prize',
  'transaction-operation.game-ticket.label': 'Premium tournament',
  'transaction-operation.swap.label': 'Swap',
  'transaction-status.created-label': 'Created',
  'transaction-status.approve_required-label': 'Waiting for approve',
  'transaction-status.approved-label': 'Approved',
  'transaction-status.started-label': 'Started',
  'transaction-status.completed-label.sent': 'Sent',
  'transaction-status.completed-label.received': 'Received',
  'select-withdrawal-wallet-page.withdrawal-error': "Seems like you don't have enough money on your wallet",
  'wallets.other-wallet': 'Other wallet',
  'wallet.withdrawal-request-created.message': 'Your withdrawal request is pending',
  'wallet.swap-request-created.message': 'Your swap request is pending',
  'neocrypto.payment-succeeded-page.placeholder.label': 'Success!',
  'neocrypto.payment-succeeded-page.placeholder.description-default':
    'We are delighted to inform you\nthat we received your payment',
  'waiting-for-transfer-page.placeholder.description-btc':
    'Payment confirmation in the BTC network can take up to 30 minutes. After the transaction is fully confirmed by the BTC network, your game balance will be replenished',
  'waiting-for-transfer-page.placeholder.description-default':
    'After payment confirmation your game balance will be replenished',
  'waiting-for-transfer-page.placeholder.label': 'Waiting for a transfer',
  'prizes-claim': {
    'title.nft-tier': 'NFT Tier',
    description: {
      'special-card': 'Get special NFT player card',
      'card-avaliable-to': 'The card will be available to',
      'will-be-sent-to-tonkeeper': 'Will be sent to your Tonkeeper',
      'get-5-cards': 'Get 5 player cards',
      'pack-avaliable-to': 'The pack will be available to',
      'pack-common-tier': 'Pack Common Tier',
      'connect-ton-wallet': 'Connect TON wallet to get your prize!',
      'will-be-credited-to-your-balance': 'Will be credited to your balance',
      'ticket-to-x-tournament': 'Ticket to {{tournament}} tournament',
      'will-be-sent-to-stonfi': 'Will be credited to your wallet. There is no need to send anything to wallets',
    },
    action: {
      'get-prize': 'Get prize',
    },
  },
  'common.place-plural_one': '{{count}}st',
  'common.place-plural_two': '{{count}}nd',
  'common.place-plural_few': '{{count}}rd',
  'common.place-plural_other': '{{count}}th',
  'common.search.placeholder': 'Search',
  'common.game.statuses.paid': 'Paid',
  'common.game.statuses.in-progress': 'In progress',
  'common.game.statuses.completed': 'Completed',
  'common.game.statuses.get-prize': 'Get prize',
  'common.game.statuses.join': 'Join',
  'common.common': 'Common',
  'common.nft': 'NFT',
  'common.tier': 'Tier',
  'common.free': 'Free',
  'common.tickets': 'Tickets',
  'prizes.participiants-in-tournament': 'Participants in tournament',
  'prizes.guaranteed-prize': 'Guaranteed prize',
  'prizes.info-about-participiants': 'Prizes are calculated from the number of participants in the tournament',
  'common.ratio': 'Ratio',
  'common.price': 'Price',
  'common.ton-connect.sign-in-btn': 'Connect wallet',
  'common.ton-connect.disconnect-wallet': 'Disconnect',
  'common.select-type': 'Select the type',
  'common.confirm-information': 'Confirm information',
  'home.tabs.main.label': 'Main',
  'home.tabs.nft.label': 'NFT',
  'home.tabs.prizes.label': 'Prizes',
  'home.tabs.support.label': 'Support',
  'home.tabs.balance.label': 'Balance',
  'home.section.tournaments.header': 'TOURNAMENTS',
  'home.section.tournaments.all-tournaments': 'All tournaments',
  'home.section.tournaments.see-all-btn': 'See all',
  'home.section.tournaments.no-games.label': 'There are no upcoming tournaments',
  'home.section.my-tournaments.header': 'MY TOURNAMENTS',
  'home.section.my-tournaments.see-all-btn': 'See all',
  'home.section.my-tournaments.placeholder.no-games.label': "You haven't started any games yet",
  'home.section.my-tournaments.placeholder.label': 'Gather your team to participate\nin tournaments',
  'home.section.my-tournaments.placeholder.action': 'Join next tournament',
  'home.slider.prizes.see-all-btn': 'See all',
  'home.slider.prizes.close-btn': 'Close',
  'home.slider.nft-cards.header': 'NFT CARDS',
  'home.slider.nft-cards.see-all-btn': 'See all',
  'tournaments.header.title': 'Tournaments',
  'common.tabs.paid.title': 'Premium',
  'games.tabs.current': 'Current',
  'games.tabs.past': 'Past',
  'cards.nft-cards.header': 'NFT Cards',
  'cards.nft-cards.buy-cards-btn': 'Buy NFT cards',
  'cards.nft-cards.placeholder.label': "You don't have NFT cards",
  'cards.nft-cards.placeholder.description': 'Buy the first one',
  'cards.nft-cards.placeholder.action': 'Buy NFT cards',
  'cards.tabs.common': 'Common',
  'cards.tabs.nft': 'NFT',
  'cards.placeholder.description.hint': 'But you may try different filter values',
  'cards.placeholder.label': 'Cards not found',
  'cards.card-advanced.player-team': "Player 's team",
  'cards.card-advanced.opposite-team': 'The opposing team',
  'cards.card-advanced.avg-pts-in-last-n': 'Average points in the last {{n, number}}',
  'notifications.header.title': 'Notifications',
  'notifications.placeholder.label': 'No new notifications',
  'notifications.placeholder.description': 'You have read all\nmessages',
  'notifications.notification.chip.get-prize': 'Get prize',
  'notifications.notification.chip.collect': 'Collect',
  'notifications.notification.default-name': 'Free common pack was awarded',
  'filters.filter.tier.label': 'Tier',
  'filters.filter.position.label': 'Player position',
  'filters.header.title': 'Filters',
  'filters.reset-btn.label': 'Reset all',
  'filters.filter.club.label': 'Club',
  'filters.filter.country.label': 'Country',
  'filters.filter.championship.label': 'Championship',
  'filters.aplly-btn.label': 'Apply',
  'filter.apply-btn.label': 'Apply',
  'filter.header.title': 'Filter',
  'games.placeholder.label': 'Tournaments not found',
  'games.placeholder.description-current': 'Choose from the current tournaments\nor come back later',
  'games.placeholder.description-past': 'The tournaments you have participated in\nwill appear here',
  'games.placeholder.action': 'Choose tournaments',
  'game.tab.prizes': 'Prizes',
  'game.tab.team': 'Team',
  'game.tab.matches': 'Matches',
  'game.tab.results': 'Results',
  'game.participants': 'Participants',
  'game.points-limit': 'Points limit',
  'position-details.header': 'Info',
  'position-details.points-detail.goal': 'Goal (without penalty)',
  'position-details.points-detail.own-goal': 'Own goal',
  'position-details.points-detail.assist': 'Assist',
  'position-details.points-detail.match-without-missing-gt-60': 'Match without missing (>60 min)',
  'position-details.points-detail.match-without-missing-lt-60': 'Match without missing (<60 min)',
  'position-details.points-detail.earned-penalty': 'Earned penalty',
  'position-details.points-detail.penalty-scored': 'Penalty scored',
  'position-details.points-detail.penalty-missed': 'Missed penalty',
  'position-details.points-detail.penalty-saved': 'Saved penalty',
  'position-details.points-detail.fouled-penalty': 'Fouled on a penalty kick',
  'position-details.points-detail.match-participation': 'Participation in the match',
  'position-details.points-detail.player-on-field-gt-60': 'Player on the field (>60 min)',
  'position-details.points-detail.first-yellow-card': 'The first yellow card',
  'position-details.points-detail.second-yellow-card': 'The second yellow card',
  'position-details.points-detail.direct-red-card': 'Direct red card',
  'position-details.points-detail.team-win': 'Team win',
  'position-details.points-detail.team-draw': 'Team draw',
  'position-details.points-detail.team-loss': 'Team loss',
  // V3 Scoring system
  'position-details.points-detail.GOALS': 'Goals',
  'position-details.points-detail.ASSISTS': 'Assists ',
  'position-details.points-detail.ACCURATE_PASSES': 'Accurate passes',
  'position-details.points-detail.ACCURATE_CROSSES': 'Accurate crosses',
  'position-details.points-detail.KEY_PASSES': 'Key passes',
  'position-details.points-detail.MISS_PASS': 'Miss pass',
  'position-details.points-detail.FOULS_DRAWN': 'Fouls drawn ',
  'position-details.points-detail.FOULS': 'Fouls ',
  'position-details.points-detail.SAVES': 'Saves ',
  'position-details.points-detail.SAVES_INSIDE_BOX': 'Saves inside box ',
  'position-details.points-detail.PUNCHES': 'Punches ',
  'position-details.points-detail.CLEANSHEET': 'Cleansheet ',
  'position-details.points-detail.MINUTES_PLAYED': 'Minutes played ',
  'position-details.points-detail.INTERCEPTIONS': 'Interceptions ',
  'position-details.points-detail.CLEARANCES': 'Clearances ',
  'position-details.points-detail.TACKLES': 'Tackles ',
  'position-details.points-detail.SHOTS_ON_TARGET': 'Shots on target',
  'position-details.points-detail.BLOCKED_SHOTS': 'Blocked shots ',
  'position-details.points-detail.HIT_WOODWORK': 'Hit woodwork ',
  'position-details.points-detail.CLEARANCE_OFFLINE': 'Clearance offline ',
  'position-details.points-detail.SUCCESSFUL_DRIBBLES': 'Successful dribbles ',
  'position-details.points-detail.PENALTIES_MISSES': 'Penalties misses ',
  'position-details.points-detail.PENALTIES_SAVED': 'Penalties saved ',
  'position-details.points-detail.PENALTIES_WON': 'Penalties won ',
  'position-details.points-detail.PENALTIES_COMMITTED': 'Penalties committed ',
  'position-details.points-detail.LONG_BALLS_WON': 'Long balls won',
  'position-details.points-detail.REDCARDS': 'Red cards ',
  'position-details.points-detail.YELLOWCARDS': 'Yellow cards ',
  'position-details.points-detail.OWN_GOALS': 'Own goals ',
  'position-details.points-detail.ERROR_LEAD_TO_GOAL': 'Error lead to goal ',
  'position-details.points-detail.GOALS_CONCEDED': 'Goals conceded',
  'position-details.points-detail.BIG_CHANCES_MISSED': 'Big chances missed ',
  'position-details.points-detail.BIG_CHANCES_CREATED': 'Big chances created',

  'position-details.reserve-player.description':
    'Receives 70% of points for his position if a player from the main squad did not enter the field. It is considered at the moment before the player left the field.',
  'team.action-label.select': 'SELECT',
  'team.position.goalkeeper': 'GK',
  'team.position.back': 'DEF',
  'team.position.midfield': 'MID',
  'team.position.forward': 'FWD',
  'team.position.reserve': 'SUB',
  'team.position.any': 'ANY',
  'team.position.any-player': 'PLAYER',
  'team.position.any-player.subtitle': 'Player',
  'team.position.all-players': 'ANY PLAYER',
  'team.payment-status.in-progress': 'We are waiting for payment confirmation.\nIt may take up 10 minutes.',
  'team.payment-status.success': 'Payment complete.',
  'team.nft-card-missing': 'Pick at least one NFT card to participate \nin NFT tournament',
  'team.select-any-player-header': 'Select player',
  'team.select-at-least-n-rarity-cards': 'Select at least {{n, number}} {{rarity}} cards',
  'team.select-up-to-n-rarity-cards': 'Select up to {{n, number}} {{rarity}} cards',
  'team.rarity-cards-are-not-allowed': '{{rarity}} cards are not allowed',
  'common.game': 'Tournament',
  team: {
    onboarding: {
      text: {
        begginerV2: '— Unlimited use of rare NFT’s\n— Limit of 2 Epic NFT\n— Limit of 1 Legendary NFT',
        beginner: 'To participate, you need at least one NFT \nof any rarity',
        amateur: "— Unlimited use of rare NFT’s\n— Limit of 1 Epic NFT\n— Legendary NFT’s aren't allowed",
        pro: '— Unlimited use of Common, Rare and Epic NFT’s\n— Limit of 1 Legendary NFT card',
        legendary: 'To participate, select at least \n2 Legendary NFT’s',
      },
    },
  },
  'cards.market-status.filters.all-cards': 'All cards',
  'cards.market-status.filters.on-sale': 'On sale',
  'cards.market-status.filters.at-auction': 'Auction',
  'cards.market-status.filters.not-for-sale': 'Not for sale',
  'commmon.sort-options.by-price': 'Sort by price',
  'common.my-nft': 'My NFT',
  'common.market': 'Market',
  'cards.actions.see-on-ggems': 'See on Getgems.io',
  'cards.actions.buy-on-ggems': 'Buy on Getgems.io',
  'filters.filter.rarity.label': 'Rarity',
  'filters.filter.price.min-price.label': 'Cost (min)',
  'filters.filter.price.max-price.label': 'Cost (max)',
  'filters.filter.price.enter-price.placeholder': 'Enter price',
  'team.captain-missing': 'Select captain to begin tournament',
  'position-details.captain': 'Captain',
  'team.too-much-players-from-same-team':
    "You can't register a team with more than {{n, number}} players from the same football club. Please change composition",
  'team.too-much-points': "Sum of players' points exceeds the total score. Please, change players",
  'team.pop-up.auth-required': 'Sign in to participate in the tournament',
  'game.actions.pay-in-currency': 'Pay {{n, number}} {{c}}',
  'game.actions.pay-with-card': 'Pay with card',
  'game.actions.play-with-this-team': 'Play with this team',
  'game.actions.save-changes': 'Save changes',
  'game.actions.placeholder.completed': 'The tour has already ended.',
  'game.actions.placeholder.in-progress.line-1': 'It is no longer possible to change the',
  'game.actions.placeholder.in-progress.line-2': 'team. The tour will be ended in:',
  'game.results.placeholder.no-started.label': 'Not so fast!',
  'game.results.placeholder.no-started.description': 'Results will be available after the end\nof the game week',
  'game.results.placeholder.no-teams.label': 'No teams registered',
  'game.results.placeholder.no-teams.description': 'This tour has no participants',
  'game.results.your-place-is': 'Your place',
  'game.results.your-position': 'Your position',
  'game.my-place': 'Place',
  'game.results.out-m': 'of {{m}}',
  'common.scoring-system': 'Scoring system',
  'game.results.points-label': 'pts',
  'game.prizes.placeholder.no-prize.label-top-n-pct': "Amazing,\nyou've entered the top {{n, number}}%",
  'game.prizes.placeholder.no-prize.description-n-teams-beaten': 'And beaten {{n}} teams',
  'game.prizes.placeholder.no-prize.same-n-usd-prize-in-premium':
    'With the same team, you could have won ${{n}} in our Premium Tournament',
  'game.prizes.placeholder.no-prizes.action': 'Play again',
  'game.prizes.placeholder.prizes-claimed.label': 'The prize is already with you!',
  'game.prizes.placeholder.prizes-ready.label.line-1': 'Congratulations!',
  'game.prizes.placeholder.prizes-ready.label.line-2': `You have finished `,
  'game.prizes.placeholder.prizes-ready.action': 'Get prize',
  'game.prizes.placeholder.prizes-claimed.action': 'Play again',
  'game.prizes.placeholder.prizes-claimed.description.line-1': 'Try your team',
  'game.prizes.placeholder.prizes-claimed.description.line-2': 'in the next tournaments',
  'game.prizes.placeholder.prizes-ready.description': 'Gain the prize!',
  'game.teams.duplicate-onboarding': {
    header: 'Duplicate team',
    description: 'Create a duplicate of the command',
    action: 'ОК',
  },
  'prizelist.prizes.n-place.title': '{{n, number}} place',
  'prizelist.prizes.n-m-places.title': '{{n, number}} - {{m}} places',
  'prizelist.prizes.top-n-%.title': 'Top {{n, number}}%',
  'prizelist.prizes.n-m-%.title': '{{n, number}}-{{m}}%',
  'prizelist.play-tournaments': 'Play tournaments',
  'team-created.placeholder.label': 'You are registered for the',
  'team-created.placeholder.action': 'OK',
  'team-created.placeholder.duplicate-to-premium': 'Duplicate team in premium',
  'team-created.placeholder.duplicate-team': 'Duplicate team',
  'team-created.placeholder.back-to-menu': 'Back to main',
  'pack-claimed.placeholder.label': 'The prize has been delivered!',
  'pack-claimed.placeholder.description.line-1': 'You have already collected the cards',
  'pack-claimed.placeholder.description.line-2': 'of this round',
  'pack-claimed.placeholder.action': 'Watch my cards',
  'team-details.total-points.titile': 'Total points per round',
  'team-details.total-points.points-label': 'pts',
  'team-details.player.action.title': 'Player action',
  'team-details.player.action.not-applicable': 'Not applicable',
  'neocrypto.placeholder.label': 'Checking the payment',
  'neocrypto.placeholder.description': 'Wait for the operation to be completed',
  'community-thumb.label': 'COMMUNITY',
  'community-thumb.btn.label': 'Join',
  'prizes.header.title': 'Prizes',
  'support.label': 'Support',
  'support.placeholder.label': 'Contact support',
  'support.placeholder.description': 'To contact the support service, you will leave the game',
  'support.placeholder.action': 'Contact support',
  'referral-program': {
    header: 'Invite a friend',
    invited: 'Invited',
    played: 'Played',
    'played-free': 'Played free',
    'played-premium': 'Played premium',
    label: 'Invite friends',
    friends: 'Friends',
    'awarded-tickets': 'Awarded tickets',
    description: 'And earn $FTON Tokens',
    'instructions-list': {
      'step-1': '1 $FTON for Free tournament player',
      'step-2': '10 $FTON for Premium tournament player',
      'step-3': 'Top 100 inviters share $1000 in $FTON',
    },
    actions: {
      share: 'Share link',
      'copy-to-friends': 'Copy link and send friends',
    },
  },
  'common.copied': 'Copied',
  'telegram-wallet-info': '«Telegram Wallet» – official wallet from Telegram',
  'balance-repenisment-ton': {
    header: 'Balance replenishment',
    actions: {
      deposit: 'Deposit',
      'deposit-in-telegram-wallet': 'Deposit in Telegram Wallet',
      'pay-another-cryptocurrencies': 'Pay another cryptocurrencies',
    },
  },
  'friends-games': {
    header: 'Friends Games',
    'play-with-friends': 'Friends play',
    'my-tournaments': 'My tournaments',
    'by-invitation': 'By invitation',
    'create-new': 'Create new tournament',
    'mine-placeholder': {
      description: 'The tournaments you create will appear here',
    },
    'invited-placeholder': {
      header: 'Invitation tournaments',
      description: 'Tournaments you were invited to will appear here',
    },
    'past-placeholder': {
      header: 'Past tournaments',
      description: 'The tournaments you played with your friends will appear here',
    },
    banner: {
      label: 'PLAY WITH FRIENDS',
      description: 'Create tournament and play Fantasy Football with your friends',
      action: 'Play',
    },
  },
  'create-game': {
    header: 'Choose tournament',
    info: 'You can create only one tournament in each league',
  },
  'common.create': 'Create',
  'game-created': {
    header: 'Congratulations, you have created a tournament',
    description: 'Create your team and then invite your friends to compete with you.',
    action: 'Create team',
  },
  'invite-friends': 'Invite friends',
  pvp: {
    homeScreenLinkLabel: 'PvP match',
    label: 'PvP tournaments',
    myPvp: 'My PvP',
    pvp: 'PvP',
  },
  'common.firstPlace': '1st place',
  leaderboars: {
    label: 'Leaderboards',
    levels: {
      amateur: 'Amateur',
      pro: 'Pro',
      worldClub: 'World Club',
    },
    nextLevel: 'Next level',
    level: 'Level',
    league: 'League',
    seasons: 'Seasons',
    howToEarnTrophies: 'How to earn trophies?',
    currentPts: 'Current Trophies',
    trophies: 'Trophies',
    trophies_count_other: 'Trophies',
    myPts: 'My Trophies',
    myPrize: 'My Prize',
    leaders: 'Leaders',
    noParticipantsPlaceholder: {
      labelPt1: 'You need',
      labelPt2: 'to get into this league',
      description: 'Play more tournaments to advance to this league',
    },
    seasonOverPlaceholder: {
      label: '{{name}} is over',
      description: "It's time to pick up the prizes",
    },
  },
  'tier-n': 'Tier {{n}}',
  rarities: {
    common: 'Common',
    rare: 'Rare',
    epic: 'Epic',
    legendary: 'Legendary',
  },
  'team.captain-not-nft-in-nft-game': 'Only NFT card can be selected as a captain',
  tasks: {
    'to-play-in-tournament-with-garantee': 'To play in Tournament with Prize pool {{s}}',
    'to-play-in-tournament': 'To play in Tournament',
    start: 'Start',
    'check-status': 'Check status',
    continue: 'Continue with tasks',
    'start-over': 'Start with tasks',
    'complete-tasks': 'Complete tasks',
  },
  'ido-promo.action': 'Join',
  'ido-promo.label': 'WELCOME TO $FTON',
  'ido-promo.description': 'Battle for $85,000 in Campaign & Join Whitelist $FTON on IDO',
  'common.lootbox': 'Lootbox',
  'no-lootboxes-placeholder': {
    label: 'Lootboxes are not available',
    description: 'We will add them soon',
  },
  'lootboxes-cards-available': 'Card available',
  'lootboxes-box-avaliable-till': 'Box available until',
  'lootboxes-buy': 'Buy {{amount}} {{currency}}',
  'lootboxes-chance': 'Chance',
  'get-card': 'Get card',
  'prize-pool': 'Prize pool',
  'euro2024promo.label': '@WHALE EURO2024 PRIZE POOL $100 000',
  'tickets-to-the': 'Tickets to the',
  'next-round': 'next round',
  leaderboards: 'Leaderboards',
  'how-to-play-winline': 'How to play Winline tournament',
  'winline-tournaments': 'Tournaments',
  'playoff-prize': 'PRIZE',
  'register-team-with-task': 'Task',
  'euro-details': 'Details',
  'euro-n-percents-get-tikcet': '{{n}}% of the best teams gets tickets to the next round',
  'euro-1-n-get-ticker': '1-{{n}} places get tickets to the next round',
  'ticket-to-next-round': 'Ticket to the next Round',
  'whale-cashback-promo': {
    label: 'Play EURO at @WHALE',
    description: 'Bet on EURO-2024 and get up to 30% cashback at @Whale',
  },
  'euro-teams': 'Teams',
  'euro-no-prize': 'No prize',
  'whale-token-promo': {
    label: '10,000,000 $Whale Token Airdrop ',
    description: 'Invite friends, Earn tokens!',
  },
};

export default ENTranslation;
