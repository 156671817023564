import React, { PropsWithChildren, useState } from 'react';
import { getUserLanguage } from '@Global/utils/getUserLanguage';
import { getAppPlatform } from '@Global/utils/platforms';

export enum ECurrenciesPair {
  TONUSD = 'TONUSD',
  TONBTC = 'TONBTC',
  TONETH = 'TONETH',
  STONUSD = 'STONUSD',
  JETTONUSD = 'JETTONUSD',
  USDTUSD = 'USDTUSD',
  TONFTON = 'TONFTON',
  TONFNTF = 'TONFNTF',
  FTONUSD = 'FTONUSD',
  FNTFUSD = 'FNTFUSD',
  XTRUSD = 'XTRUSD',
  USDTTRC20USD = 'USDTTRC20USD',
  USDTERC20USD = 'USDTERC20USD',
  TESTTUSDUSD = 'TESTTUSDUSD',
}

export type TAppContext = {
  locale: string;
  appEnv: TAppEnv;
  currencyRates: {
    [key in ECurrenciesPair]: number;
  };
  setCurrencyRate: (key: ECurrenciesPair, value: number) => void;
};

export type TAppEnv = 'telegram' | 'tonkeeper' | 'neocrypto' | 'web';

const initialState: TAppContext = {
  locale: 'en',
  appEnv: 'telegram',
  currencyRates: {} as TAppContext['currencyRates'],
  setCurrencyRate: () => {},
};

export const AppContext = React.createContext<TAppContext>(initialState);

export const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = getUserLanguage();
  const appEnv = getAppPlatform();

  const [currencyRates, setCurrencyRates] = useState({} as TAppContext['currencyRates']);

  const setCurrencyRate = (key: ECurrenciesPair, value: number) => {
    setCurrencyRates((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <AppContext.Provider value={{ locale, appEnv, currencyRates, setCurrencyRate }}>{children}</AppContext.Provider>
  );
};
